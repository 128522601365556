@use "assets/scss/config/variables" as var;

.TextArea {
  padding: 14px;
  border: 1px solid var.$GRAY_400;
  border-radius: 6px;
  color: var.$GRAY_800;
  textarea {
    width: 100%;
    border: transparent;
    box-sizing: border-box;
    font-family: "monospace", "Noto Sans JP", sans-serif;
    color: var.$GRAY_800;
    outline: none;
    white-space: pre-wrap;
    display: inline-block;
    &::placeholder {
      color: var.$GRAY_700;
    }
  }
}
