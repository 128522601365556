.Center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    height: 100%;
  }
}
