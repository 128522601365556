@use "assets/scss/config/variables" as var;

// color
$titleColor: #3a6096; //タイトル背景色
$baseColor: #92aad4; // 背景色
$myTextColor: #8de386; // 吹き出しの色
// icon
$iconSize: 23px;

.LineSimulator {
  position: relative;
  padding: 0;
  background: $baseColor;
  overflow: hidden;
  min-width: 240px;
  max-width: 240px;
  margin: 0 auto;
  font-size: 10px;
  height: 100%;
  border-radius: 6px;

  /* 会話部分 */
  .line__contents {
    padding: 4px;
    overflow: hidden;
    line-height: 135%;

    &.scroll {
      height: 100%;
      overflow-y: auto;
      padding: {
        top: 10px;
        bottom: 10px;
      }
    }

    /* 相手の会話 */
    .line__left {
      position: relative;
      display: block;
      width: 215.28px;

      /* アイコン画像 */
      figure {
        width: $iconSize;
        position: absolute;
        top: 0px;
        left: 0;
        padding: 0;
        margin: 0;
        /* 正方形を用意 */
        img {
          border-radius: 50%;
          width: $iconSize;
          height: $iconSize;
        }
      }

      .line__left-message {
        display: flex;
        margin: {
          left: 28px;
        }
        padding: {
          top: 5px;
        }
      }
      /* コメントエリア */
      .message {
        font-size: 12px;
        max-width: 195.28px;
        position: relative;
        padding: 8px 12px;
        border-radius: 14px;
        background-color: #ffffff;
        &.first {
          &::after {
            content: "";
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            left: -3px;
            top: 1px;
            border-right: 10px solid #ffffff;
            border-bottom: 10px solid transparent;
            transform: rotateZ(15deg);
          }
        }
        .line-message-placeholder {
          color: var.$GRAY_600;
        }
        &.postback {
          width: 195.28px;
          border-radius: 10px;
          padding: {
            left: 10px;
            right: 10px;
            bottom: 0;
          }
        }
        .postback {
          &-title {
            font-size: 12px;
            font-weight: bold;
            margin: {
              top: 3px;
              bottom: 5px;
            }
          }
          &-exp {
            font-size: 10.4px;
            margin: {
              bottom: 10px;
            }
            color: var.$GRAY_800;
          }
          &-bar {
            width: calc(100% + 20px);
            height: 0;
            border-top: 0.5px solid var.$GRAY_400;
            margin: {
              left: -10px;
            }
          }
          &-choice {
            margin: 10px auto;
            text-align: center;
            color: $titleColor;
          }
        }
      }
    }
  }
  .LineSimulatorRendering {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: $baseColor;
    border-radius: 6px;
    .l-s-center {
      width: 30px;
      height: 30px;
      margin-bottom: 30px;
      animation: rotation 1s linear infinite;
    }
    @keyframes rotation {
      from {
        rotate: 0deg;
      }
      to {
        rotate: 360deg;
      }
    }
  }
}
