@use "assets/scss/config/variables" as var;

.h1,
.h2,
.h3,
.h4,
.h5 {
  display: block;
}

.h1 {
  font-weight: bold;
  font-size: var.$FONT_SIZE_HEADLINE_H1;
}

.h2 {
  font-weight: bold;
  font-size: var.$FONT_SIZE_HEADLINE_H2;
}

.h3 {
  font-weight: bold;
  font-size: var.$FONT_SIZE_HEADLINE_H3;
}

.h4 {
  font-weight: bold;
  font-size: var.$FONT_SIZE_HEADLINE_H4;
}

.h5 {
  font-weight: bold;
  font-size: var.$FONT_SIZE_HEADLINE_H5;
}

.xs {
  font-size: var.$FONT_SIZE_TEXT_XS;
}

.sm {
  font-size: var.$FONT_SIZE_TEXT_SM;
}

.md {
  font-size: var.$FONT_SIZE_TEXT_MD;
}

.lg {
  font-size: var.$FONT_SIZE_TEXT_LG;
}

.black {
  color: var.$BLACK;
}

.gray_400 {
  color: var.$GRAY_400;
}

.gray_600 {
  color: var.$GRAY_600;
}

.gray_700 {
  color: var.$GRAY_700;
}

.gray_800 {
  color: var.$GRAY_800;
}

.danger {
  color: var.$DANGER;
}
