@use "assets/scss/config/variables" as var;

.QuoCardHistoryEditor {
  .buttons {
    display: flex;
    justify-content: right;
    margin: {
      bottom: 5px;
    }
  }
  .build-questionnaire-button {
    margin: 15px auto;
    display: block;
  }
  .circle-button-edit,
  .circle-button-trash {
    margin: {
      left: 2px;
    }
  }
  .plus-button {
    margin: {
      top: 30px;
    }
  }
  .QuoCardEditor {
    height: calc(100vh - 150px);
    margin: 15px;
    .quo-dialog {
      overflow: scroll;
      height: calc(80% - 25px);
    }
    .quocard-group-editor-title {
      border-bottom: 1px solid var.$GRAY_600;
      padding: {
        bottom: 3px;
      }
      margin: {
        top: 15px;
        bottom: 5px;
      }
    }
    .discript-next-selector {
      margin: {
        bottom: 50px;
      }
    }
    .editor-close-button {
      display: block;
      margin: {
        top: auto;
        left: auto;
        right: auto;
        bottom: 20px;
      }
    }
  }
  .inline-block_quo {
    display: inline-block;
    width: 100%;
    padding: 20px;
  }
  .DropDown.quo-dropdown {
    width: 200px;
  }
  .quo-table {
    table-layout: fixed;
    margin: {
      top: 20px;
      left: auto;
      right: auto;
      bottom: 20px;
    }
    border-collapse: collapse;
  }
  .quo-tr {
    border-bottom: 1px solid #dedede;
  }
  .quo-td {
    padding: 20px;
    vertical-align: top;
  }
  .quo-body {
    vertical-align: top;
  }
  .quo-comment {
    text-align: left;
    min-width: 300px;
    max-width: 300px;
  }
  .quo-comment-hidden {
    text-align: left;
    min-width: 300px;
    max-width: 300px;
    max-height: 50px;
    visibility: hidden;
  }
}
