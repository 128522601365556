@use "assets/scss/config/variables" as var;

.QuestionnaireEditor {
  .buttons {
    display: flex;
    justify-content: right;
    margin: {
      bottom: 5px;
    }
  }
  .build-questionnaire-button {
    margin: 15px auto;
    display: block;
  }
  .QuestionBlock {
    border-top: 1px solid var.$GRAY_400;
    padding: {
      top: 10px;
    }
    .question-editor{
      height:"calc(100% - 110px)"
    }
  }
  .circle-button-edit,
  .circle-button-trash {
    margin: {
      left: 2px;
    }
  }
  .plus-button {
    margin: {
      top: 30px;
    }
  }

  .QuestionEditor {
    height: calc(101vh - 150px);
    margin: 15px;
    .question-editor-title {
      border-bottom: 1px solid var.$GRAY_600;
      padding: {
        bottom: 3px;
      }
      margin: {
        top: 15px;
        bottom: 5px;
      }
    }
    .plus-button {
      margin: {
        top: 30px;
      }
    }
    .discript-next-selector {
      margin: {
        bottom: 50px;
      }
    }
    .choise-close-button {
      display: block;
      margin: {
        top: auto;
        left: auto;
        right: auto;
        bottom: 20px;
      }
    }
    #patient-group-multiple-checkbox {
      background-color: white;
    }
  }

  .ChoiceBlock {
    margin: {
      bottom: -10px;
    }
    .choice-next-selector {
      margin: {
        top: 5px;
      }
    }
    .trash-button {
      display: flex;
      justify-content: right;
      margin: {
        top: 5px;
      }
    }
  }
}
