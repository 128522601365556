@use "assets/scss/config/variables" as var;

.Layout {
  position: relative;
  box-shadow: 0 1px 5px var.$SHADOW;
}

.Pagebox {
  position: relative;
  z-index: 0;
  height: 100vh;
  margin: {
    left: 0;
  }
  background: var.$GRAY_200;
  .pageview {
    box-sizing: border-box;
    padding: {
      top: 45px;
      right: 10px;
      left: 10px;
    }
    overflow: scroll;
    height: 100vh;
  }
  transition: 0.2s;
  &.sidebarActive {
    margin: {
      left: 250px;
    }
  }
}
