@use "assets/scss/config/variables" as var;

.signup {
  margin: 50px auto;
  .logo {
    width: 250px;
    margin: 0 auto;
  }
  &__title {
    margin: 15px 0;
    text-align: center;
  }
  &__comment {
    display: block;
    text-align: center;
  }
}
