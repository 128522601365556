@use "assets/scss/config/variables" as var;

.PersonProfile {
  .profile {
    display: flex;
    justify-content: center;
    margin: 10px;
    margin: {
      bottom: 17px;
    }
    .icon {
      width: 40px;
      height: 40px;
    }
    .icon,
    .carte,
    .birth,
    .quo,
    .answer,
    .send {
      margin: {
        right: 20px;
      }
    }
  }
}
