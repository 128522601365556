@use "assets/scss/config/variables" as var;

.Participant {
  height: 90%;
  padding: {
    top: 15px;
  }
  width: 100%;
  max-width: 100%;
  .text-box-container {
    display: inline-block;
    max-width: 500px;
    width: 500px;
    margin: 0 auto;
  }
  .index-container {
    display: inline-block;
    max-width: 150px;
    margin-left: 20px;
  }
  .value-container {
    display: inline-block;
    max-width: 150px;
    margin-left: 20px;
  }
  .search-button {
    display: inline-block;
    max-width: 150px;
    height: 39px;
    width: 90px;
    text-align: center;
    margin-left: 20px;
  }
  .patient-count-text{
    display: inline-block;
    font-size: medium;
    margin-left: 20px
    ;
  }
  .select-button {
    display: inline-block;
    float: right;
    bottom: 40px;
  }
  .participant-content {
    height: 95%;
    display: flex;
    width: 100%;
    .no-valid-line-data {
      padding: {
        top: 42px;
      }
      height: 100%;
    }
    .participants-list {
      display: flex;
      width: 100%;
      max-width: 100%;
      flex-wrap: wrap;
      justify-content: space-around;
    }
    .participant-container {
      position: relative;
      flex-grow: 1;
      margin: 5px;
      width: 120%;
      cursor: pointer;
      transition: 0.1s;
      &:hover {
        background: var.$GRAY_200;
      }
      &.selected {
        background: var.$GRAY_400;
      }
      .patient-checkbox {
        position: absolute;
        top: 5px;
        left: 5px;
      }
      .alert {
        position: absolute;
        top: 0;
        color: var.$DANGER;
        text-align: center;
        background: var.$GRAY_400;
        width: 100%;
      }
      .PersonProfile {
        margin: {
          top: 20px;
          right: 10px;
          left: 10px;
        }
      }
    }
  }
  .bottom-button-bar {
    padding: {
      bottom: 15px;
    }
    display: flex;
    justify-content: space-between;
    .all-select,
    .all-deselect {
      margin: {
        right: 5px;
      }
    }
    .send-line-dm {
      display: block;
      margin: {
        right: 5px;
      }
    }
  }
}

.SelectedParticipantList {
  width: 360px;
  background: var.$GRAY_300;
  height: 100%;
  border-radius: 5px;
  .selected-title {
    width: 360px;
    text-align: center;
    padding: {
      top: 10px;
      bottom: 5px;
    }
  }
  .selected-internal {
    padding: {
      bottom: 30px;
    }
    height: calc(100% - 45px);
  }
  .selected-internal-no-height {
    padding: {
      bottom: 30px;
    }
    height: calc(100% - 45px);
  }
}

.SendLineDirectMessage {
  .line-dm-message {
    margin: {
      right: 50px;
      left: 50px;
    }
    .dm-title {
      padding: {
        top: 20px;
        bottom: 5px;
      }
      border-bottom: 1px solid var.$GRAY_400;
    }
    .dm-exp {
      display: inline-block;
      padding: {
        top: 10px;
        bottom: 15px;
      }
    }
    .line-simulate-box {
      display: flex;
      height: calc(60vh - 50px);
      .dm-textarea {
        text-align: start;
        width: 100%;
        margin: {
          left: 10px;
        }
      }
    }
    .dm-button {
      text-align: center;
      margin: {
        top: 11px;
        bottom: 11px;
      }
    }
  }
  .loader {
    animation: rotation 1s linear infinite;
    height: 30px;
    width: 30px;
  }
  @keyframes rotation {
    from {
      rotate: 0deg;
    }
    to {
      rotate: 360deg;
    }
  }
}
