@use "assets/scss/config/variables" as var;

.SideBar {
  position: fixed;
  top: 0;
  left: -250px;
  z-index: 1;
  width: 250px;
  height: 100vh;
  box-shadow: 0 1px 5px var.$SHADOW;
  background: var.$WHITE;
  overflow: scroll;
  transition: 0.2s;
  &.active {
    left: 0;
  }
  .chevrons {
    height: 20px;
    margin: {
      top: 10px;
    }
    padding: {
      right: 5px;
    }
    text-align: right;
    svg {
      opacity: 0.1;
      stroke: var.$BLACK;
      cursor: pointer;
      transition: 0.2s;
      &:hover {
        opacity: 1;
      }
    }
  }
}
