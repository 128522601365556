.ConfirmDialog {
  text-align: center;
  .dialog-title {
    margin: {
      top: 40px;
      bottom: 20px;
    }
    padding: 0 30px;
  }
  .dialog-content {
    max-height: 50vh;
    padding: 0 30px;
    .dialog-text {
      margin: 5px;
      white-space: pre;
    }
  }
  .button-container {
    margin: {
      top: 20px;
      bottom: 15px;
    }
    .TextButton {
      margin: 15px;
    }
  }
}
