@use "assets/scss/config/variables" as var;

.Error404 {
  margin: {
    top: 30vh;
  }
  width: 100%;
  text-align: center;
  color: var.$GRAY_600;
  .Text {
    margin: 15px;
  }
}
