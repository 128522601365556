@use "assets/scss/config/variables" as var;

.AnswerEditor {
  padding: {
    top: 10px;
    left: 10px;
    right: 10px;
  }
  .table {
    overflow: scroll;
    height: calc(50vh - 60px);
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      overflow: scroll;
      thead {
        z-index: 2;
        position: relative;
      }
      tbody {
        z-index: 1;
        position: relative;
      }
      tr {
        border: {
          bottom: solid 1px #eee;
        }
      }
      th,
      td {
        text-align: center;
        min-width: 150px;
        padding: 15px 5px;
        font-size: 14px;
      }
    }
  }
}
