@use "assets/scss/config/variables" as var;

.RegistrationDoctor {
  max-width: 700px;
  margin: 25px auto;
  padding: 25px 0;
  text-align: center;
  * {
    margin: 15px auto;
  }
}

.registration-doctor-dialog {
  .issance-email {
    font-weight: bold;
    margin: {
      right: 5px;
    }
  }
  .clickable-url {
    cursor: pointer;
    transition: 0.1s;
    opacity: 1;
    &:hover {
      opacity: 0.5;
    }
  }
}
