@use "assets/scss/config/variables" as var;

.RegistrationParticipant {
  max-width: 700px;
  margin: 25px auto;
  padding: 45px 0;
  svg {
    display: block;
    margin: 0 auto;
  }
  .textbox {
    margin: 35px;
    text-align: center;
    * {
      padding: 5px;
    }
  }
}
