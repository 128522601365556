.Spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Spin {
  animation: loading 0.8s infinite linear;
  transform: rotate(360deg);
  padding-top: 0.15px;
  width: 25px;
  height: 25px;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
