@use "assets/scss/config/variables" as var;

.PatientGroupTextEdit {
  max-width: 700px;
  margin: 5px auto;
  padding: 12px;
  .exp {
    margin: {
      top: 15px;
      bottom: 10px;
    }
  }
}
.NoPatientGroups {
  margin-top: 275px;
}
.registerButtonParent {
  display: table;
  align: center;
}
.registerButton {
  position: absolute;
  margin-left: -50px;
  left: 45%;
  bottom: 20px;
}
.PatientEditor {
  max-width: 700px;
  margin: 0px auto;
  margin: {
    top: 15px;
    bottom: 10px;
  }
  padding: 5px 5px 5px 10px;
  .patient-editor-box {
    margin-left: 30px;
    margin-right: 20px;

    .patient-editor-title {
      margin: {
        top: 5px;
        bottom: 5px;
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: right;
    margin: {
      bottom: 5px;
    }
  }
  .circle-button-edit,
  .circle-button-trash {
    margin: {
      left: 5px;
      bottom: 5px;
    }
  }
  .PatientBlock {
    border-top: 1px solid var.$GRAY_400;
    padding: {
      top: 10px;
    }
  }
}
