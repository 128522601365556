@use "assets/scss/config/variables" as var;

.TextForm {
  display: block;
  width: 95%;
  max-width: 500px;
  height: 55px;
  margin: 20px auto;
  padding: 0 18px;
  border: 1.5px solid var.$BLACK;
  border-radius: 5px;
  box-sizing: inherit;
  outline: none;
  box-sizing: border-box;
  &.readonly {
    border: 2px solid var.$GRAY_700;
    background: var.$GRAY_300;
    color: var.$GRAY_800;
  }
}
