@use "assets/scss/config/variables" as var;

.LoadingCircle {
  width: 100%;
  height: 100%;
  margin: 65px auto;
  border-radius: 6px;
  .l-s-center {
    width: 30px;
    height: 30px;
    animation: rotation 1s linear infinite;
  }
  @keyframes rotation {
    from {
      rotate: 0deg;
    }
    to {
      rotate: 360deg;
    }
  }
}
