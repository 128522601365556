@use "assets/scss/config/variables" as var;

.TextCompresser {
  position: relative;
  cursor: pointer;
  .full-text {
    display: none;
    position: absolute;
    width: 100%;
    padding: 10px;
    border: 0.5px solid var.$BORDER;
    border-radius: 5px;
    box-shadow: 0 1px 5px var.$SHADOW;
    background: var.$WHITE;
  }
  &:hover {
    .full-text {
      display: block;
    }
  }
}
