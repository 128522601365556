@use "assets/scss/config/variables" as var;

.IconButton {
  letter-spacing: 0.1em;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  background: rgba($color: #ffffff, $alpha: 0.5);
  border: 1px solid var.$GRAY_600;
  color: var.$GRAY_800;
  padding: 2px 15px;
  &:hover {
    background: rgba($color: #000000, $alpha: 0.05);
  }
  &.disable {
    color: var.$GRAY_700;
    background: rgba($color: #000000, $alpha: 0.05);
    cursor: default;
  }
  .button-icon {
    margin: {
      top: 2px;
      bottom: 2px;
    }
    height: 20px;
  }
}
