@use "assets/scss/config/variables" as var;

.Badge {
  color: #7d7d7d;
  height: 18px;
  background: #f1f1f1;
  border-radius: 9px;
  padding: {
    top: 2px;
    left: 9px;
    right: 9px;
  }
  font-weight: bold;
  font-size: 10px;
  cursor: default;
}
