@use "assets/scss/config/variables" as var;

input {
  outline: none;
  &::placeholder {
    color: var.$GRAY_700;
  }
}
// パスワードの目を非表示する
::-ms-reveal {
  visibility: hidden;
}
