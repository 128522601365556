@use "assets/scss/config/variables" as var;

.QuestionnaireBundleEdit {
  max-width: 700px;
  margin: 5px auto;
  padding: 12px;
  .exp {
    margin: {
      top: 15px;
      bottom: 10px;
    }
  }
}
