@use "assets/scss/config/variables" as var;

.LeadButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  max-width: 500px;
  height: 55px;
  margin: 20px auto;
  border-radius: 10px;
  background: var.$BLACK;
  color: var.$WHITE;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.1em;
  text-align: center;
  cursor: pointer;
}
