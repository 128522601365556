@use "assets/scss/config/variables" as var;

.QuestionnaireTitle {
  margin: 0 10px;
  .types {
    margin: 5px 0;
    * {
      font-size: 12px;
      padding-right: 15px;
    }
  }
}
