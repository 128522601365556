@use "assets/scss/config/variables" as var;

.Header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  padding: 10px 18px;
  box-sizing: border-box;
  box-shadow: 0 1px 5px var.$SHADOW;
  background: var.$GRAY_100;
  letter-spacing: 0.2em;
  .headerContent {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span.h5 {
      padding: 4px 12px;
    }
    .chevrons {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      opacity: 0.2;
      margin: {
        right: 10px;
      }
      transition: 0.2s;
      &:hover {
        opacity: 1;
      }
      svg {
        stroke: var.$GRAY_800;
        cursor: pointer;
      }
    }
  }
}
