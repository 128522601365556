@use "assets/scss/config/variables" as var;

.PatientRegisterDialogue {
  height: calc(100vh - 150px);
  margin: 15px;
  .patient-editor-title {
    border-bottom: 1px solid var.$GRAY_600;
    padding: {
      bottom: 3px;
    }
    margin: {
      top: 15px;
      bottom: 5px;
    }
  }
  .dialog-content {
    max-height: 97%;
    padding: 0 30px;
  }
  .patient-list-group-name {
    font-size: medium;
    resize: none;
    overflow: hidden;
    min-height: 5vh;
    max-height: 5vh;
    text-align: start;
    background-color: white;
  }
  .patient-list-note {
    resize: none;
    overflow: hidden;
    word-wrap: break-word;
    margin-top: 5px;
    min-height: 10.5vh;
    max-height: 10.5vh;
    text-align: start;
    background-color: white;
  }
  .patient-list-text-box {
    resize: none;
    overflow: hidden;
    min-height: 45vh;
    text-align: start;
    background-color: white;
  }
  .editor-register-button {
    display: block;
    margin: {
      top: auto;
      left: auto;
      right: auto;
      bottom: 20px;
    }
  }
}
