/**
* Typography
*/
// nomal
$FONT_SIZE_NORMAL: 14px;
// headline
$FONT_SIZE_HEADLINE_H6: 10px;
$FONT_SIZE_HEADLINE_H5: 12px;
$FONT_SIZE_HEADLINE_H4: 14px;
$FONT_SIZE_HEADLINE_H3: 16px;
$FONT_SIZE_HEADLINE_H2: 18px;
$FONT_SIZE_HEADLINE_H1: 23px;
// text
$FONT_SIZE_TEXT_XS: 10px;
$FONT_SIZE_TEXT_SM: 12px;
$FONT_SIZE_TEXT_MD: 14px;
$FONT_SIZE_TEXT_LG: 16px;

/**
* Color
*/
// black
$BLACK: #212121;
// gray
$GRAY_100: #fafafa;
$GRAY_200: #f1f1f1;
$GRAY_300: #e8e8e8;
$GRAY_400: #dddddd;
// no 500
$GRAY_600: #afafaf;
$GRAY_700: #7d7d7d;
$GRAY_800: #4a4a4a;
// white
$WHITE: #ffffff;
// other
$DANGER: #d6082e;

// IBD-LINQ
$BORDER: $GRAY_600;
$BORDER_DOT: $GRAY_800;
$SHADOW: rgba(0, 0, 0, 0.1);
