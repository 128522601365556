.Person {
  display: block;
  width: 100%;
  height: 100%;
  &.male {
    fill: skyblue;
  }
  &.female {
    fill: pink;
  }
}
