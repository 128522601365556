@use "assets/scss/config/variables" as var;

.DropDown {
  position: relative;
  min-width: 100px;
  font-family: "monospace", "Noto Sans JP", sans-serif;
  color: var.$GRAY_800;
  .control {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border: 1px solid var.$GRAY_400;
    border-radius: 6px;
    box-sizing: border-box;
    background: var.$WHITE;
    cursor: pointer;
    &:hover {
      background: var.$GRAY_200;
    }
  }

  // 非活性表示
  .control_disable {
    color: white;
    background-color: gray;
    &:hover {
      background-color: gray;
    }
  }
  .menu {
    position: absolute;
    top: 45px;
    z-index: 99;
    width: 100%;
    border: 1px solid var.$GRAY_400;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: 0 1px 5px var.$SHADOW;
    background: var.$WHITE;
    .option {
      text-align: center;
      padding: {
        top: 5px;
        bottom: 5px;
      }
      cursor: pointer;
      &:hover {
        background: var.$GRAY_200;
      }
    }
  }
}
