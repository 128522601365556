@use "assets/scss/config/variables" as var;

.TableData {
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 100%;
  min-height: 200px;
  tr {
    border-bottom: solid 1px #eee;
  }
  th,
  td {
    text-align: center;
    padding: 15px 0;
    font-size: 14px;
    &.sex {
      display: block;
      min-width: 60px;
    }
    &.carte {
      min-width: 120px;
    }
    &.content {
      min-width: 150px;
    }
    &.edit {
      display: block;
      width: 75px;
      margin: 0 auto;
    }
  }
  td {
    &.icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.table-no-data-wall {
  height: auto;
  position: absolute;
}

.AnswerRaw {
  .icon {
    .Person {
      width: 24px;
      height: 24px;
    }
  }
}
