.signin {
  margin: 50px auto;
  .logo {
    display: flex;
    width: 250px;
    margin: 0 auto;
    .Badge {
      margin: {
        top: 15px;
        left: 5px;
      }
    }
  }
  &__title {
    margin: 25px 0;
    text-align: center;
  }
}
