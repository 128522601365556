@use "assets/scss/config/variables" as var;

.PasswordForm {
  position: relative;
  width: 95%;
  max-width: 500px;
  height: 55px;
  margin: 20px auto;
  input {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 18px;
    border: 1.5px solid #212121;
    border-radius: 5px;
    box-sizing: inherit;
    outline: none;
    box-sizing: border-box;
  }
  span {
    position: absolute;
    top: 16px;
    right: 16px;
    left: auto;
    cursor: pointer;
    svg {
      width: 23px;
      color: var.$BLACK;
    }
  }
}
