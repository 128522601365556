.BuildBlock {
  margin: {
    bottom: 10px;
  }
  .build-block-title {
    display: block;
    margin: {
      bottom: 3px;
    }
  }
  .build-block-exp {
    display: block;
    margin: {
      bottom: 10px;
    }
  }
}
