.Card {
  max-width: 700px;
  word-wrap: break-word;
  margin: 25px auto;

  margin: {
    top: 10px;
    bottom: 10px;
  }

  padding: 5px 30px 14px 30px;
  .detail {
    padding: 5px 0;
  }
}
