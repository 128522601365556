@use "assets/scss/config/variables" as var;

.text-box-container {
  position: relative;
  margin: auto;
  .TextBox {
    width: 100%;
    height: 40px;
    border: 1px solid var.$GRAY_400;
    border-radius: 6px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: "monospace", "Noto Sans JP", sans-serif;
    color: var.$GRAY_800;
    background: var.$WHITE;
    padding: 20px 15px;
    &.un-match {
      border-color: red;
    }
    &.search-box {
      padding: {
        left: 40px;
      }
    }
  }
  .text-box-icon {
    position: absolute;
    top: 13px;
    left: 15px;
    color: var.$GRAY_700;
  }
}
