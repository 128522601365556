@use "assets/scss/config/variables" as var;

.DragAndDropField {
  .d-n-d-element {
    transition: 0.2s;
    border: {
      top: 3px solid;
      bottom: 3px solid;
      color: rgba(0, 0, 0, 0);
    }
    &.dragging {
      opacity: 0.2;
    }
    &.emp-top {
      border: {
        top: {
          color: var.$GRAY_700;
        }
      }
    }
    &.emp-bottom {
      border: {
        bottom: {
          color: var.$GRAY_700;
        }
      }
    }
  }
}
