@use "assets/scss/config/variables" as var;

.Modal {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &.dialog {
    z-index: 2;
  }
  .overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var.$BLACK;
    opacity: 0.2;
  }
  .modal-container {
    position: relative;
    z-index: 2;
    width: 90%;
    max-width: 1200px;
    margin: 20vh auto;
    // min-height: 200px;
    &.dialog {
      width: 60%;
      max-width: 800px;
    }
    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    .center {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      .content {
        width: 100%;
      }
    }
  }
}
