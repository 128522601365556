@use "assets/scss/config/variables" as var;

.SideHeader {
  display: block;
  width: 200px;
  margin: 3px auto 5px auto;
  padding-bottom: 20px;
  border-bottom: 2px dotted var.$BORDER_DOT;
  .logo {
    display: flex;
    width: 150px;
    padding-bottom: 12px;
    .Badge {
      margin: {
        top: 5px;
        left: 2px;
      }
    }
  }
  .Text {
    padding: 3px 0;
    &.user {
      font-weight: bold;
    }
  }
}

.SideMenu {
  margin: 20px 0;
  * {
    width: 100%;
  }
}

.SideMenuContent {
  position: relative;
  padding: 8px 0;
  cursor: pointer;
  .context {
    display: flex;
    position: relative;
    z-index: 1;
    padding: 8px 0 8px 25px;
    width: 225px;
    color: var.$BLACK;
    font-weight: bold;
    .icon {
      width: 35px;
      height: 25px;
      padding-right: 10px;
      svg {
        stroke: var.$BLACK;
      }
    }
  }
  .background {
    position: absolute;
    top: 0;
    opacity: 0;
    height: 57px;
    background: linear-gradient(
      to right,
      #5a5a5a 0%,
      #5a5a5a 2%,
      #eaeaea 2%,
      #eaeaea 100%
    );
    transition: 0.3s;
  }
  &:hover {
    .background {
      opacity: 1;
    }
  }
  &.active {
    .background {
      opacity: 0.5;
    }
  }
}
