.LoadingPage {
  position: relative;
  width: 100%;
  margin-top: 35vh;
  .comment {
    position: absolute;
    top: -40px;
    width: 100%;
    text-align: center;
  }
}
