@use "assets/scss/config/variables" as var;

.QuestionnaireFindings {
  overflow: scroll;
  padding: {
    bottom: 5px;
  }
  background: var.$GRAY_100;
  height: calc(100vh - 50px);
  overflow: scroll;
  .detail {
    display: flex;
    margin: 10px;
    * {
      padding-right: 15px;
    }
    button {
      font-size: 10px;
      cursor: pointer;
    }
    column-gap: 10px;
  }

  svg {
    &.male {
      fill: skyblue;
    }
    &.female {
      fill: pink;
    }
  }
}
