.qr-line-registration-button {
  position: absolute;
  bottom: auto;
  right: 20px;
}

.LineRegistrationModal {
  text-align: center;
  .token {
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 0.3em;
    margin: {
      top: 45px;
      bottom: 30px;
    }
  }
  .qr-code {
    margin: {
      top: 65px;
      bottom: 50px;
    }
    height: 50vh;
    width: 50vh;
  }
  .line-auth-button {
    margin: {
      top: 15px;
      bottom: 50px;
    }
  }
  .token-text {
    margin: {
      left: 30px;
      right: 30px;
      bottom: 15px;
    }
  }
}
