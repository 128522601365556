.ParticipantData {
  position: relative;
  padding: {
    top: 15px;
    bottom: 50px;
  }
  .back-button {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .tabledata-box {
    padding: 5px 30px;
    overflow: scroll;
  }
  .no-answer-data {
    text-align: center;
    padding: {
      top: 30px;
      bottom: 50px;
    }
  }
  .quoHistoryButton {
    position: absolute;
    top: 30px;
    right: 30px;
  }
}
