@use "assets/scss/config/variables" as var;

.CircleButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid var.$GRAY_700;
  border-radius: 50%;
  background: rgba($color: #ffffff, $alpha: 0.5);
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background: rgba($color: #000000, $alpha: 0.05);
  }
  svg {
    width: 14px;
    height: 14px;
    stroke: var.$GRAY_700;
  }
}
