@use "assets/scss/config/variables" as var;

.QuestionnaireBundle {
  max-width: 700px;
  margin: 15px auto;
  .questionnaire-box {
    padding: {
      top: 5px;
      bottom: 10px;
    }
    .questionnaire-bundle-title {
      margin: {
        bottom: 15px;
      }
    }
  }
}

.QuestionnaireBlock {
  border-top: 1px solid var.$GRAY_400;
  padding: {
    top: 10px;
  }
  .bundle-buttons {
    display: flex;
    justify-content: right;
    margin: {
      top: -10px;
    }
    .send-button {
      margin: {
        left: 3px;
      }
    }
  }
}

.ImplementQuestionnaire {
  .implement-start-message {
    margin: {
      right: 50px;
      left: 50px;
    }
    .i-s-m-title {
      padding: {
        top: 20px;
        bottom: 5px;
      }
      border-bottom: 1px solid var.$GRAY_400;
    }
    .i-s-m-exp {
      display: inline-block;
      padding: {
        top: 10px;
        bottom: 15px;
      }
    }
    .line-simulate-box {
      display: flex;
      height: calc(60vh - 50px);
      .dm-textarea {
        word-break:break-all;
        text-align: start;
        width: 100%;
        margin: {
          left: 10px;
        }
      }
    }
    .i-s-m-button {
      text-align: center;
      margin: {
        top: 11px;
        bottom: 11px;
      }
    }
  }
}

.questionnaire-bundle-alert {
  display: inline-block;
  margin: 12px;
}
