@use "assets/scss/config/variables" as var;

.Wall {
  display: inline-flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &__icon {
    color: var.$GRAY_700;
  }
  &__msg {
    padding: {
      top: 20px;
      bottom: 40px;
    }
    color: var.$GRAY_700;
  }
}
